import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';
import moment from 'moment'

const STEP = 1;
const COLORS = ['#000000', '#000', '#000000'];
const TOOL_TIP_STYLES1={
    position: 'absolute',
        top: '-28px',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '10px',
        fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
        padding: '4px',
        borderRadius: '4px',
        backgroundColor: '#000',
        width:'115px',
};

const TOOL_TIP_STYLES2 = {};

Object.assign(TOOL_TIP_STYLES2,TOOL_TIP_STYLES1);

TOOL_TIP_STYLES2.top="-54px";

class MultipleThumbs extends React.Component {

     MIN = 0;
     MAX = 100;

    constructor(props) {
        super(props);
        this.MIN = this.props.range[0];
        this.MAX = this.props.range[this.props.range.length-1];

        this.state = {
            values: [this.MIN, this.MAX]
        };
    }

    componentDidMount() {
        this.rangeChange(this.state.values)
    }
    rangeChange(values){
        this.setState({ values });
        this.props.filterRange(this.state.values[0],this.state.values[1]);
    }

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                }}
            >

                <Range
                    values={this.state.values}
                    step={STEP}
                    min={this.MIN}
                    max={this.MAX}
                    onChange={values => {this.rangeChange(values)}}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '120px',
                                display: 'flex',
                                width: '80%'
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '5px',
                                    width: '100%',
                                    borderRadius: '4px',
                                    background: getTrackBackground({
                                        values: this.state.values,
                                        colors: COLORS,
                                        min: this.MIN,
                                        max: this.MAX
                                    }),
                                    alignSelf: 'center'
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged, index }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '42px',
                                width: '42px',
                                borderRadius: '4px',
                                backgroundColor: '#FFF',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 2px 6px #AAA'
                            }}
                        >


                            { index === 0 &&

                            <div style={ TOOL_TIP_STYLES1}>
                                { moment(this.state.values[0]).format("lll")}
                            </div>

                            }


                            { index === 1 &&
                            <div style={TOOL_TIP_STYLES2}>
                                { moment(this.state.values[1]).format("lll")}
                            </div>

                            }

                            <div
                                style={{
                                    height: '16px',
                                    width: '5px',
                                    backgroundColor: isDragged ? COLORS[index] : '#CCC'
                                }}
                            />
                        </div>
                    )}
                />
                <output className={"position-absolute"} style={{marginTop:84}} >
                    <div className="d-flex flex-row bd-highlight justify-content-between">
                        <div  className="align-self-start  bg-dark text-white p-1">Showing {this.props.count}/{this.props.total} Events</div>
                    </div>
                </output>
            </div>
        );
    }
}

export default MultipleThumbs;