import React, {Component} from 'react';
import PropTypes from "prop-types";
import TextAreaInput from "./TextAreaInput";
import Form from 'react-bootstrap/Form';

class TextInput extends Component {
    render() {
        return (

            <Form.Group controlId={this.props.name}>
                <Form.Label>{this.props.label}</Form.Label>
                <Form.Control required="required" name={this.props.name} placeholder={this.props.label} value={this.props.value} onChange={this.props.handleInputChange}/>

                <Form.Text className="text-muted">
                    {this.props.description}
                </Form.Text>


            </Form.Group>

        );
    }
}

TextAreaInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default TextInput;
