import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppMain from "./components/AppMain";
import ChiitikoAlert from "./components/ChiitikoAlert";
import './styles/animate.min.css';
import {ToastContainer, toast, cssTransition} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class App extends Component {

    constructor() {
        super();
        this.state = {
            areas: [
                {
                    id: 1,
                    label: "ss",
                    value: "jhgjh"
                },
                {
                    id: 2,
                    label: "sffs",
                    value: "jhfgfgjh"
                }
            ],
        };
    }

    addMessage(title,message, type = "info") {
        const Effect = cssTransition({
            enter: 'fadeInUp',
            exit: 'fadeOutRight',
        });
        toast.update(toast(""), {
            render: <ChiitikoAlert title={title} message={message} type={type}/>,
            autoClose: 4200,
            transition: Effect,
        });
    }

    render() {
        return (
            <div className="App">

                <main className="main">

                    <ToastContainer
                        autoClose={4200}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                        className='toast-container'
                        toastClassName="dark-toast"
                    />

                    <div className="container">
                        <AppMain addMessage={this.addMessage.bind(this)} areas={this.state.areas}/>
                    </div>
                </main>

            </div>
        );
    }
}

export default App;
