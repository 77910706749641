import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {ColumnToggle, Search, CSVExport} from 'react-bootstrap-table2-toolkit';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
}];

const {ToggleList} = ColumnToggle;
const {SearchBar, ClearSearchButton} = Search;
const {ExportCSVButton} = CSVExport;

class PointTable extends Component {

    columns = [
        {
            dataField: 'subject',
            text: 'subject',
            sort: true
        },
        {
            dataField: 'details',
            isDummyField: true,
            text: 'Details',
            formatter: (cellContent, row) => {
                return (
                    <Button onClick={() => {
                        this.props.togglePopup(row)
                    }} variant="light" size="sm">Details</Button>
                );
            }
        },
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    moment(row.date).format("ll")
                );
            }
        },
        {
            dataField: 'Location-3-2',
            text: 'Location',
            isDummyField: true,
            sort: true,
            hidden: true,
            formatter: (cellContent, row) => {
                return (
                    row.location3 + "," + row.location2
                );
            },

        },
        {
            dataField: 'location1',
            text: 'Country',
            sort: true,
            hidden: true
        },
    ];

    render() {

        return (
            <ToolkitProvider
                keyField="id"
                data={this.props.mapFilteredPoints}
                columns={this.columns}
                columnToggle
                search
                defaultSorted={defaultSorted}
                striped bordered hover
                pagination={paginationFactory()}
            >
                {
                    props => (
                        <div className="justify-content-center">
                            <Form>
                                <Row>
                                    <Col xs={8}>
                                        <SearchBar classNAme={""} {...props.searchProps} />
                                    </Col>
                                    <Col xs={4}>
                                        <ClearSearchButton className={"btn btn-outline-dark"} {...props.searchProps} />
                                    </Col>
                                </Row>
                            </Form>
                            <hr/>
                            <h6>Filter Columns:</h6>
                            <ToggleList contextual="outline-secondary"
                                        className={"btn-group-sm align-content-start  d-flex flex-wrap"} {...props.columnToggleProps} />
                            <hr/>
                            <BootstrapTable
                                classes={"table-dark responsive"}
                                {...props.baseProps}
                            />

                            <ExportCSVButton className={'btn btn-outline-info'} {...props.csvProps}>Export CSV!!</ExportCSVButton>
                        </div>
                    )
                }
            </ToolkitProvider>
        );
    }
}

/*
Map.propTypes={
    mapPoints:PropTypes.array.isRequired
};
*/

export default PointTable;