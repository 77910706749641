import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {markerStyles, greatPlaceStyleHover} from './markerStyles.js';

class Marker extends Component {

    constructor() {
        super();
        this.state = {
            showMiniPopup: false,
        };
    }

    toggleMiniPopup() {
        this.setState({
            showMiniPopup: !this.state.showMiniPopup
        });
    }

    truncate(str, max) {
        return str.length > max ? str.substr(0, max - 1) + '…' : str;
    }

    render() {

        const style = this.props.hover ? greatPlaceStyleHover : markerStyles;

        return (

            <div className="hint hint--html hint--info hint--top" style={style}
                 onClick={this.toggleMiniPopup.bind(this)}>

                <svg id="i-location" viewBox="0 0 32 32" width="32" height="32" fill="none" stroke="currentcolor"
                     strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                    <circle cx="16" cy="11" r="4"/>
                    <path d="M24 15 C21 22 16 30 16 30 16 30 11 22 8 15 5 8 10 2 16 2 22 2 27 8 24 15 Z"/>
                </svg>

                {this.state.showMiniPopup ?

                    <Card className="card mini-popup">
                        <Card.Body>
                            <Card.Title>{this.props.markerData.subject}</Card.Title>
                            <Card.Text>
                                {this.truncate(this.props.markerData.description, 17)}
                            </Card.Text>
                            <Button variant="primary" className={"btn-dark"} onClick={() => {
                                this.props.togglePopup(this.props.markerData)
                            }}>More Details</Button>
                        </Card.Body>
                        <svg id="i-arrow-bottom" viewBox="0 0 32 32" width="32" height="32" fill="none"
                             stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                            <path d="M6 22 L16 30 26 22 M16 30 L16 2"/>
                        </svg>
                    </Card>
                    : null}


            </div>
        )
    }

}

Marker.propTypes = {
    position: PropTypes.object,
    map: PropTypes.object
};


export default Marker;
