import React, {Component} from 'react';

import TextInput from "./TextInput";
import TextAreaInput from "./TextAreaInput";
import PropTypes from "prop-types";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import uuidv1 from 'uuid/v1';


class ChiitikoForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            id: "",
            subject: '',
            country: '',
            province: '',
            town: '',
            description: '',
            coordinatesDetected: {latitude: 0, longitude: 0},
            coordinatesGeolocation: {Latitude: 0, Longitude: 0},
            gotDeviceGeoLocation: false,
            showNextSection: false,
            geoLocationDenied: false,

        };
        this.handleInputChange = this.handleInputChange.bind(this);


        if ("geolocation" in navigator) {

            navigator.geolocation.getCurrentPosition((position) => {
                this.setCoordinatesDetected(position);

                this.setState({
                    geoLocationDenied: false,
                });

            }, ((error) => {

             if(error){
                 this.setState({
                     geoLocationDenied: true,
                 });
             }

            }));
        } else {
            this.setState({
                geoLocationDenied: true,
            });
        }

    }

    clearForm() {
        this.setState({
            id: "",
            subject: '',
            country: '',
            province: '',
            town: '',
            description: '',
            coordinatesDetected: {latitude: 0, longitude: 0},
            coordinatesGeolocation: {Latitude: 0, Longitude: 0},
            gotDeviceGeoLocation: false,
            showNextSection: false,
            geoLocationDenied:false,
        })
    };


    sendChiitiko(chiitiko) {

        chiitiko = this.transformChiitiko(chiitiko);

        fetch("https://chiitiko.onrender.com/api/chiitiko", {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify(chiitiko)

        }).then(res => res.json())
            .then(
                (result) => {
                    this.props.addMessage("Success", "Sent Chiitiko!", "success");
                    this.clearForm();

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.props.addMessage("Oops", "Failed To Send", "danger");
                    console.log(error);
                })
    }

    getAddressFromCords(position) {
        let pos = "latlng=" + position.latitude + "," + position.longitude;
        fetch("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCDMVBrW507Q3loZ3zX-GTCTl69KToxGbA&" + pos).then(res => res.json())
            .then(
                (result) => {

                    let obj = {};

                    result.results[0].address_components.forEach((item) => {

                        if (item.types.includes("locality")) {

                            obj.town = item.long_name;
                        }

                        if (item.types.includes("administrative_area_level_1")) {

                            obj.province = item.long_name;
                        }

                        if (item.types.includes("country")) {

                            obj.country = item.long_name;
                        }
                    });

                    this.setState({
                        town: obj.town,
                        province: obj.province,
                        country: obj.country,

                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.props.addMessage("Oops", "Failed To Location", "danger");
                    console.log(error);
                })
    }

    getCordsFromAddress(town, province, country) {

        let address = "address=" + town + "+" + province + "+" + country;
        fetch("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCDMVBrW507Q3loZ3zX-GTCTl69KToxGbA&" + address).then(res => res.json())
            .then(
                (result) => {


                    if (result.results.length > 0) {

                        this.setState({
                            coordinatesGeolocation: {
                                Latitude: result.results[0].geometry.location.lat,
                                Longitude: result.results[0].geometry.location.lng
                            },
                        });
                    }

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.props.addMessage("Oops", "Failed To Set event Geolocation", "danger");
                    console.log(error);
                })
    }

    setCoordinatesDetected(position) {

        if (position.coords.accuracy < 5000) {

            this.setState({
                coordinatesDetected: {latitude: position.coords.latitude, longitude: position.coords.longitude},
                coordinatesGeolocation: {latitude: position.coords.latitude, longitude: position.coords.longitude},
            });

        }
    }

    transformChiitiko(chiitiko) {

        let transform = {
            id: chiitiko.id,
            date: chiitiko.date,
            subject: chiitiko.subject,
            description: chiitiko.description,
            location1: chiitiko.country,
            location2: chiitiko.province,
            location3: chiitiko.town,
            coordinatesGeolocation: chiitiko.coordinatesGeolocation,
            coordinatesDetected: chiitiko.coordinatesDetected
        };
        console.log("Sending");
        console.log(transform);

        return transform;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "description" && value.length > 50 && this.state.geoLocationDenied===false && this.state.gotDeviceGeoLocation === false) {

            this.getAddressFromCords(this.state.coordinatesDetected);

            this.setState({
                gotDeviceGeoLocation: true,
                showNextSection: true,
            });
        }

        if (name === "description" && value.length > 50 && this.state.geoLocationDenied===true){

            this.setState({
                showNextSection: true,
            });
        }


        if ((name === "country" || name === "town" || name === "province")) {

            this.getCordsFromAddress(this.state.town, this.state.province, this.state.country);
        }


        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        event.preventDefault();

        if (this.state.description.length < 50) {

            this.props.addMessage("Description", "Must Be longer than 50 Characters !", "warning");

        } else {

            this.setState({
                id: uuidv1(),
                date: Date.now(),
            }, () => {

                this.sendChiitiko(this.state);
                this.props.addMessage("Got It", "Posting Chiitiko!");
            });

        }
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>


                <Card>
                    <Card.Body>
                        <Card.Title>What Occurred ?</Card.Title>

                        <TextInput label={"Subject"} name={"subject"} value={this.state.subject}
                                   handleInputChange={this.handleInputChange}/>

                        {/*TODO make blow nicer*/}
                        <TextAreaInput label={"Description"} name={"description"} value={this.state.description}
                                       description={(() => {
                                           if (this.state.description.length < 50) {
                                               return <div className="alert alert-warning fadeIn"
                                                           role="alert">Description Must Be longer than 50
                                                   Characters</div>;
                                           }
                                       })()}
                                       handleInputChange={this.handleInputChange}/>

                    </Card.Body>
                </Card>


                {(() => {
                    if (this.state.showNextSection) {

                        return <Card className="mt-3">
                            <Card.Body>
                                <Card.Title>Where ?</Card.Title>

                                <TextInput label={"Town"} name={"town"} value={this.state.town}
                                           handleInputChange={this.handleInputChange}/>
                                <TextInput label={"Province or State"} name={"province"}
                                           description={"e.g Mashonaland West Province or California"}
                                           value={this.state.province}
                                           handleInputChange={this.handleInputChange}/>
                                <TextInput label={"Country"} name={"country"} value={this.state.country}
                                           handleInputChange={this.handleInputChange}/>

                            </Card.Body>
                        </Card>

                    }
                })()}


                <div className="text-center">
                    <Button variant="dark" className="btn-lg" type="submit">
                        Submit
                    </Button>
                </div>

            </Form>
        );
    }
}


ChiitikoForm.propTypes = {
    areas: PropTypes.array.isRequired
};

export default ChiitikoForm;