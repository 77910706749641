import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";
import Details from "./Details";
import MultipleThumbs from "./MultipleThumbs";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PointTable from "./PointTable";

class Map extends Component {

    constructor() {
        super();
        this.state = {
            secondaryKey: 'map',
            showPopup: false,
            details: null,
            refresh: false,
            mapPoints: [],
            mapFilteredPoints: [],
        };
    }

    handlSsecondaryNavChange(secondaryKey) {

        this.setState({secondaryKey: secondaryKey})

    }

    filterRange(min, max) {

        let filteredPoints = this.state.mapPoints.filter((item) => (item.date >= min && item.date <= max));
        // console.log(min+"-"+max);
        // console.log(filteredPoints.length);
        this.setState({mapFilteredPoints: filteredPoints});
    }


    togglePopup(markerData) {

        this.setState({
            showPopup: !this.state.showPopup,
            details: markerData
        });
    }

    static defaultProps = {
        center: {
            lat: -19.063398, lng: 30.208805
        },
        zoom: 5.2
    };

    gerMapData() {

        fetch("https://chiitiko.onrender.com/api/chiitiko", {
            method: 'get',
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.props.addMessage("Got!", "New Map Data", "success");

                    const raw = result.payload;

                    const n = raw.sort(function (a, b) {
                        return a.date - b.date
                    });


                    this.setState({
                        mapPoints: n
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.props.addMessage("Oops", "Failed To get map data", "danger");
                    console.log(error);
                }
            )
    }

    componentDidMount() {
        this.gerMapData();

        if (window.EventSource!==undefined) {
            const evtSource = new EventSource("https://chiitiko.onrender.com/api/chiitiko/event");
            evtSource.addEventListener('newChiitiko',(e)=> {
                this.gerMapData();
                console.log(this.state.refreshMap);
                console.log(e.data);
            }, false);

        }
    }



    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):

        if (prevProps.refresh === false && this.props.refresh === true) {
            this.gerMapData();
        }
    }

    render() {

        const mapOptions = {
            styles: [
                {
                    "stylers": [
                        {
                            "hue": "#ff1a00"
                        },
                        {
                            "invert_lightness": true
                        },
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 33
                        },
                        {
                            "gamma": 0.5
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#2D333C"
                        }
                    ]
                }
            ]
        };

        return (

            <div style={{height: '75vh', width: '100%'}}>

                {this.state.mapPoints.length > 0 &&
                <MultipleThumbs total={this.state.mapPoints.length} count={this.state.mapFilteredPoints.length}
                                range={this.state.mapPoints.map(item => item.date)}
                                filterRange={this.filterRange.bind(this)}/>
                }


                <Tabs className="justify-content-center"
                      id="controlled-tabs"
                      activeKey={this.state.secondaryKey}
                      onSelect={this.handlSsecondaryNavChange.bind(this)}
                >

                    <Tab className="text-left p-3" eventKey="map" title="Map">
                        <div className="tab-pane vh-100" id="map" role="tabpanel" aria-labelledby="map-tab">
                            <GoogleMapReact
                                bootstrapURLKeys={{key: "AIzaSyCDMVBrW507Q3loZ3zX-GTCTl69KToxGbA"}}
                                defaultCenter={this.props.center}
                                defaultZoom={this.props.zoom}
                                options={mapOptions}
                                yesIWantToUseGoogleMapApiInternals={true}
                            >

                                {this.state.mapFilteredPoints.map((point) => {


                                    let position = {
                                        lat: point.coordinatesGeolocation.latitude,
                                        lng: point.coordinatesGeolocation.longitude
                                    };

                                    return <Marker key={point.id} togglePopup={this.togglePopup.bind(this)}
                                                   markerData={point}
                                                   position={position} lat={point.coordinatesGeolocation.latitude}
                                                   lng={point.coordinatesGeolocation.longitude}/>

                                })}

                            </GoogleMapReact>

                        </div>
                    </Tab>
                    <Tab className="text-left p-3" eventKey="table" title="Table">
                        <div className="tab-pane overflow-auto" id="table" role="tabpanel" aria-labelledby="table-tab">
                            <PointTable togglePopup={this.togglePopup.bind(this)}
                                        mapFilteredPoints={this.state.mapFilteredPoints}
                                        addMessage={this.props.addMessage}/>
                        </div>
                    </Tab>
                </Tabs>

                {this.state.showPopup ? <Details addMessage={this.props.addMessage} details={this.state.details}
                                                 closePopup={this.togglePopup.bind(this)}/> : null}
            </div>
        );
    }
}

/*
Map.propTypes={
    mapPoints:PropTypes.array.isRequired
};
*/

export default Map;