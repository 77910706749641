import React, {Component} from "react";
import ChiitikoForm from "./ChiitikoForm";
import Map from "./Map";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


class AppMain extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            key: 'home',
            refreshMap: false,
        };
    }

    componentDidMount() {


    }



    handleNavChange(key) {

        if (key === "map" && window.EventSource===undefined) {

            this.setState({refreshMap: true})
        }

        else {
            this.setState({refreshMap: false})
        }

        this.setState({key: key})

    }

    render() {
        return (
            <Tabs className="justify-content-center main-nav"
                  id="controlled-tabs"
                  activeKey={this.state.key}
                  onSelect={this.handleNavChange.bind(this)}
            >
                <Tab className="text-left p-3" eventKey="home" title="Home">
                    <div className="tab-pane" id="about" role="tabpanel" aria-labelledby="home-tab">
                        <ChiitikoForm addMessage={this.props.addMessage} areas={this.props.areas}/>
                    </div>
                </Tab>
                <Tab className="text-left p-3" eventKey="map" title="Map">
                    <div className="tab-pane" id="map" role="tabpanel" aria-labelledby="map-tab">

                        <Map addMessage={this.props.addMessage} refresh={this.state.refreshMap}/>


                    </div>
                </Tab>
                <Tab className="text-left p-3" eventKey="about" title="About">
                    <div className="tab-pane" id="about" role="tabpanel" aria-labelledby="about-tab">
                        <h1>What Is Chiitiko?</h1> <p className="lead"> Chiitiko is a event tracker, it links events to
                        locations.Anyone with Internet access can post an event and rate a events truthfulness,
                        Users can contribute anonymously and view events posted by other users.</p>
                    </div>
                </Tab>
            </Tabs>
        );
    }
}


export default AppMain;