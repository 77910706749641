import React, {Component} from 'react';
import PropTypes from "prop-types";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import moment from 'moment'

class Details extends Component {

    constructor(props) {
        super(props);
        this.state = {
            details: this.props.details,
        };
    }

    render() {

        let divStyle = {
            display: 'block',
        };
        return (
            <div className="modal" id="marker-full-info" tabIndex="-1" role="dialog" style={divStyle}
                 aria-labelledby="marker-full-info" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header  justify-content-center">

                            <button onClick={this.props.closePopup} type="button" className="close p-0 m-0"
                                    data-dismiss="modal" aria-label="Close">

                                <svg id="i-close" viewBox="0 0 32 32" width="32" height="32" fill="none"
                                     stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round"
                                     strokeWidth="2">
                                    <path d="M2 30 L30 2 M30 30 L2 2"/>
                                </svg>

                            </button>

                        </div>

                        <div className="modal-header text-center justify-content-center">

                            <h5 id="popup-date" className="modal-title p-1">
                                { moment(this.state.details.date).format("llll")}
                            </h5>

                        </div>
                        <div className="modal-body overflow-auto">

                            <div className="bd-example">
                                <dl>
                                    <dt>Subject</dt>
                                    <dd id="popup-subject">
                                        {this.state.details.subject}
                                    </dd>

                                    <dt>Province</dt>
                                    <dd id="popup-province">
                                        {this.state.details.location2}
                                    </dd>

                                    <dt>Town</dt>
                                    <dd id="popup-town">{this.state.details.location3}</dd>

                                    <dt>Description</dt>
                                    <dd id="popup-description">

                                        {this.state.details.description}

                                    </dd>
                                </dl>
                            </div>
                        </div>

                        <div className="modal-footer justify-content-center position-relative">
                            <Button onClick={this.handlePositiveRatingClick.bind(this)} type="button"
                                    className="btn btn-dark" id="popup-rating-increase">

                                True&nbsp;
                                <svg className="mr-2 ml-2" id="i-chevron-top" viewBox="0 0 32 32" width="32"
                                     height="32" fill="none"
                                     stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round"
                                     strokeWidth="2">
                                    <path d="M30 20 L16 8 2 20"/>
                                </svg>

                                <span
                                    className="badge badge-light">{this.getRatingNumber(this.state.details.positiveRatings)}</span>

                            </Button>
                            <Button onClick={this.handleNeagtiveRatingClick.bind(this)} type="button"
                                    className="btn btn-dark" id="popup-rating-decrease">

                                False&nbsp;
                                <svg className="mr-2 ml-2" id="i-chevron-bottom" viewBox="0 0 32 32" width="32"
                                     height="32" fill="none"
                                     stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round"
                                     strokeWidth="2">
                                    <path d="M30 12 L16 24 2 12"/>
                                </svg>

                                <span
                                    className="badge badge-light">{this.getRatingNumber(this.state.details.negativeRatings)}</span>
                            </Button>
                            <OverlayTrigger
                                trigger="click"
                                key={"left"}
                                placement={"left"}
                                overlay={
                                    <Popover id={`popover-positioned-${"left"}`} title={`Rating`}>
                                        Rate <strong>True</strong> if factual and Rate <strong>False</strong> is suspicious
                                    </Popover>
                                }
                            >
                                <Button   style={{width:"25px",height:"25px",right: 7,top: 7}}  className={"rounded-circle p-0 position-absolute"} variant="secondary">?</Button>
                            </OverlayTrigger>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handlePositiveRatingClick() {
        this.rate(this.state.details.id, true)
    }

    handleNeagtiveRatingClick() {
        this.rate(this.state.details.id, false)
    }

    getRatingNumber(array) {

        if (array === null || array === undefined) {

            return 0;
        }

        return array.length;
    }

    rate(id, postive) {

        let endpoint = postive ? "https://chiitiko.onrender.com/api/chiitiko/increase-rating/" : "https://chiitiko.onrender.com/api/chiitiko/decrease-rating/";

        fetch(endpoint + id, {method: "post"})
            .then(res => res.json())
            .then(
                (result) => {

                    let details = Object.assign({}, this.state.details);

                    if (postive) {
                        details.positiveRatings = result.payload;
                    }
                    else {
                        details.negativeRatings = result.payload;

                    }

                    this.setState({
                        details: details
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {

                    console.log(error);
                    this.props.addMessage("Oops", "Failed Rate", "danger");

                }
            )
    }
}

Details.propTypes = {
    position: PropTypes.object,
    map: PropTypes.object
};


export default Details;
