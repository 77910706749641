import React, {Component} from 'react';
import Alert from 'react-bootstrap/Alert';

class ChiitikoAlert extends Component {
    constructor(props) {
        super(props);
        this.state = { show: true };
    } render() {
       /* const handleHide = () => this.setState({ show: false });*/
        return (
                <Alert variant={this.props.type}  className="text-left">
                    <strong>{this.props.title}</strong>
                    <p className={"m-0"}>
                        {this.props.message}
                    </p>
                </Alert>
        );
    }


}

export default ChiitikoAlert;